
















































































































.display-chip
  animation-name alive
  animation-duration 0.75s
a
  cursor pointer
  &:hover
    color primary
    text-decoration underline

@keyframes alive
  0%
    transform scale(1.5)
  100%
    transform scale(1)
