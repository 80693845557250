.title {
  font-size: 16px;
  color: #424242;
}
.subtitle {
  font-size: 14px;
}
.organisation {
  font-size: 12.8px;
}
.subtitle,
.organisation {
  color: #777;
}
.subtitle {
  text-transform: capitalize;
}
span {
  display: block;
}
/*# sourceMappingURL=src/components/MSearchResultItem/index.css.map */