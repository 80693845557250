.q-card-section-overlay {
  background-color: transparent;
}
.q-card-section-overlay .q-card-subtitle {
  color: rgba(0,0,0,0.6);
}
.col.column {
  width: 8%;
}
.icon-type {
  left: 8px;
  top: 80px;
}
.booking {
  width: 100%;
  overflow: hidden;
}
.unknownBackground {
  height: 120px;
  width: 100%;
  background-color: #fff;
}
.is-cancelled {
  opacity: 0.75;
}
.is-cancelled:after {
  content: "";
  position: absolute;
  height: 4px;
  background-color: #616161;
  outline: 4px #fff solid;
  top: 50%;
  left: -12px;
  right: -12px;
  transform: translateY(-50%) rotate(45deg);
}
/*# sourceMappingURL=src/components/MBookingCardList/booking-card.css.map */