.header-content-type {
  display: flex;
}
.header-content-type p {
  line-height: 1.2;
  margin-left: 16px;
}
.header-price {
  color: #747474;
}
.header-price small {
  display: block;
  text-align: right;
}
.header-price .price-amount {
  font-weight: 700;
}
.time-info {
  line-height: 1.1;
}
.weather-text {
  text-align: right;
  margin-right: 8px;
}
.emissions-text {
  line-height: 1.1;
  margin-top: 6px;
  margin-right: 8px;
}
/*# sourceMappingURL=src/peeks/contextual/info-card.css.map */