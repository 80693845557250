.bottom-sheet,
.fixed-sheet {
  position: relative;
}
.fixed-sheet .drawer {
  width: 100%;
  min-height: 100%;
  padding-bottom: 0;
}
.bottom-sheet {
  top: calc(100vh - 108px);
  padding-top: 32px;
  z-index: 1;
}
.drawer {
  border-radius: 16px;
  padding-bottom: calc(54px + env(safe-area-inset-bottom));
}
.dragbar {
  position: relative;
  z-index: 1;
}
.dragbar:after {
  content: '';
  position: absolute;
  top: 8px;
  left: calc(50vw - 4vw);
  width: 8vw;
  height: 4px;
  border-radius: 4px;
}
/*# sourceMappingURL=src/components/MBottomSheet/index.css.map */