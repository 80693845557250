
































































.text-white
  color: white !important
.q-card
  margin: 16px
.q-icon
  top 90px
  background-color #fff
  border-radius: 100%
  padding 8px
  z-index 1
.q-skeleton
  width 50px
  margin auto
