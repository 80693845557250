




















































.imageNewTabBtn
  background-color rgba(255, 255, 255, 0.25)
