
















































































































































































.date-picker
  width 300px
  display inline-block

.date-picker:nth-child(2)
  margin-left 15px

.date-range-dialog .modal-content
  width 663px

.custom-string
  display: table-cell;
  vertical-align: middle;
  height: 100%
  line-height: 1
  padding-left: 10px
  height 56px
