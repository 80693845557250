.map,
.map-container {
  height: 25vh;
  width: 100%;
  font-size: 8vmax;
  color: rgba(255,255,255,0.7);
  overflow: hidden;
  background: #d1cfcf;
  text-overflow: clip;
}
/*# sourceMappingURL=src/peeks/contextual/route-map.css.map */