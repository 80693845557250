




































.top-sheet
  padding 0 1rem
  position relative
  z-index 100

  @media (min-width: 768px)
    padding 0 2rem

.title
  margin-top 15px
    @media (min-width: 768px)
      margin-bottom 15px
h4
  text-transform capitalize
  font-weight 300

.table-head
  display none

@media (min-width: 768px) {
  .table-head {
    display: flex;
  }

  .title {
    margin-top 2rem
    margin-bottom 0
  }
}
