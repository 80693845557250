.detail-modal .q-toolbar-title {
  padding-top: 8px;
  padding-bottom: 8px;
}
.detail-modal .layout-padding {
  padding: 24px;
}
.detail-modal .q-list {
  flex-basis: auto;
  max-width: none;
}
.detail-modal .q-list .q-item img.avatar,
.detail-modal .q-list .q-item-label header img.avatar {
  width: 38px;
  height: 38px;
}
.loader {
  width: 100px;
  height: 100px;
  margin: auto;
}
/*# sourceMappingURL=src/components/MDiscoveryDetailModal/index.css.map */