@media (max-width: 768px) {
  .q-card {
    width: 100vw;
    margin: 0;
  }
}
.no-existing-journeys {
  margin-top: 10px;
}
.journey-error {
  font-size: small;
  color: #f00;
  text-align: center;
}
/*# sourceMappingURL=src/components/MAttachToBookingRequest/index.css.map */