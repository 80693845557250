




















































































.topBar
  height 75px
.buttonBar
  height 50px
  display flex
  justify-content center
  align-items center
  .q-btn
    width 100px
    margin 0 10px
.mobilityNearbyList
  z-index 11
  width 800px
  height 800px
  position absolute
  background-color white
  bottom 0
  right calc(50% - 400px)
  .q-btn
    font-size .75em
    padding 2px 4px 2px 4px
.departure
  border-top solid 1px lighten(black, 55)

.loadingContainer
  .spinner
    position absolute
    left calc(50% - 32px)
    top calc(50% - 32px)

@media only screen and (max-width: 800px)
  .mobilityNearbyList
    width 100%
    right 0
