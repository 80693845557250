

























































































































































.avatar
  width 400px
  height 400px

.btn-upload
  line-height 2
