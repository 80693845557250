





























































































































.orgDisabled, .userDisabled.user
  opacity: 0.3
  .q-avatar:after
    content ""
    position absolute
    height 4px
    background-color $grey-8
    outline 4px white solid
    top 50%
    left -8px
    right -8px
    transform translateY(-50%) rotate(45deg)

.orgDisabled.user
  display: none
