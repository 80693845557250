.or {
  margin-top: 20px;
}
.concierge-peek .q-textarea .q-field__native {
  min-height: 260px;
}
.q-tabs >>> .q-hoverable:hover > .q-focus-helper {
  background: transparent !important;
}
.q-tabs__arrow--left,
.q-tabs__arrow--right {
  top: 16px !important;
}
/*# sourceMappingURL=src/peeks/concierge/question.css.map */