














































































.q-spinner-geo
  animation q-spin 2s linear infinite
