.q-footer {
  padding-bottom: env(safe-area-inset-bottom);
}
.q-footer--hidden .option-button .q-btn {
  display: none;
}
.q-tabs >>> .q-tabs__content {
  overflow: visible !important;
}
.q-tabs >>> .q-tab {
  padding-top: 4px;
}
@media (max-width: 599px) {
  .q-tabs >>> .q-tab__label {
    font-size: 11px;
  }
  .q-tab {
    padding: 0;
    text-transform: none;
  }
  .q-tab--full {
    min-height: 0;
  }
}
/*# sourceMappingURL=src/components/MMobileFooter/index.css.map */