#card-carousel {
  min-height: 140px;
  padding: 8px 0;
  display: flex;
  position: absolute;
  bottom: -60px;
  right: 0;
  left: 0;
  z-index: 2;
  margin-bottom: 5vh;
}
@media (min-width: 768px) {
  #card-carousel {
    width: 84%;
    left: 8%;
  }
}
.card-row {
  width: 100%;
  display: flex;
  padding: 2px 0;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.card-row::-webkit-scrollbar {
  display: none;
}
.q-card {
  scroll-snap-align: center;
  background: #fff;
  height: 100%;
  min-width: 300px;
  max-width: 300px;
  margin: 0 4px;
  padding: 5px 10px;
  border-radius: 15px;
  display: grid;
  grid-template-columns: auto 24px;
  align-items: start;
  grid-template-areas: "card-name type-icon"   "search-type ."   "buttons buttons";
}
@media (min-width: 768px) {
  .q-card {
    min-width: 60%;
  }
}
.empty-state {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
}
.card-name {
  color: #406e8e;
  font-weight: 500;
  grid-area: card-name;
  font-size: 95%;
}
.search-type {
  font-size: 85%;
  grid-area: search-type;
}
.button-holder {
  grid-area: buttons;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.q-btn {
  width: 105px;
}
.q-btn >>> .q-btn__wrapper {
  padding: 4px 8px;
}
.scroll-button {
  background: rgba(255,255,255,0.6);
  box-shadow: none;
  margin: 2px 0;
  width: 35px;
}
.scroll-button.left {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.scroll-button.right {
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}
.parking-info {
  position: relative;
  top: 21px;
  font-size: 70%;
}
/*# sourceMappingURL=src/components/MMobilityNearbyCards/index.css.map */