.index-head {
  padding: 15px 0;
  width: 100%;
}
@media (min-width: 1024px) {
  .index-head {
    width: 75% !important;
  }
}
.col-head {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
.col-head span {
  line-height: 1.5;
}
@media (min-width: 1024px) {
  .full-width.index-head {
    width: 100% !important;
  }
}
/*# sourceMappingURL=src/components/MResourceTableHead/index.css.map */