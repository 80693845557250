




































































































.q-infinite-scroll
  width 100%
.q-infinite-scroll > .q-infinite-scroll-content
  display flex
  flex-wrap wrap
.error
  width 400px
  color black
  text-align center
  margin auto
  padding 20px

  p
    margin-bottom 15px

.infinite-loading
  margin auto
