







































































































































.remove-club
  text-align center
  display grid

  *
    margin auto
    margin-top: 10px

.please-enter
  display grid
  text-align center
  margin 10px 20px

.btn-delete
  margin-top 16px
