


































































































































.q-card
  max-width 600px

.validation
  color red
  font-size 90%
