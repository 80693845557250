.index-head {
  display: grid;
  width: calc(100% - 25px);
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.col-head {
  position: relative;
  padding: 10px 12px;
}
.col-head.sortable {
  cursor: pointer;
  transition: background-color 0.3s;
}
.col-head.sortable:hover {
  background: #e1e1e1;
}
/*# sourceMappingURL=src/components/MIndexList/columns.css.map */