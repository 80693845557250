

























































































































































































































































































.q-card
  @media (max-width 768px)
    width 100vw
    margin 0

.no-existing-journeys
  margin-top 10px

.journey-error
  font-size small
  color red
  text-align center
