








































.invoice
  border-bottom 1px solid rgba(0, 0, 0, 0.125)
.q-item
  background-color #fafafa
  box-shadow 0px 4px 5px rgba(0, 0, 0, 0.125)
  &:hover
    background-color #efefef
