.q-btn i {
  font-size: 14px;
  margin-right: 10px;
}
.time-transport-return {
  background-color: #fff;
  border: none;
  width: 100%;
  display: grid;
  grid-template-columns: 20px auto auto 20px;
  grid-column-gap: 5px;
  align-items: center;
  padding: 10px 5px;
}
.button-label,
.left-icon {
  justify-self: start;
}
.button-value,
.right-icon {
  justify-self: end;
}
.dropdown-enter {
  height: 0px;
}
.dropdown-leave-to {
  height: 0px;
}
.dropdown-enter-active {
  transition: height 0.5s;
}
.dropdown-leave-active {
  transition: height 0.5s;
}
/*# sourceMappingURL=src/components/MLeaveNow/index.css.map */