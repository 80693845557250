.layout-padding {
  max-width: 1024px;
}
.col-md-4 {
  padding: 8px 0 0 8px;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-md-4 {
    width: 33%;
  }
}
/*# sourceMappingURL=src/components/MJourneyCardList/index.css.map */