.btn-upload-wrapper {
  display: grid;
}
.btn-upload {
  margin: auto;
  line-height: 3;
  margin-left: 10px;
  font-size: 12px;
}
.upload,
.upload-text {
  line-height: 1.4;
}
.img-card {
  position: relative;
  margin-top: 12px;
  height: 50px;
  width: 100%;
  display: grid;
}
.img-card:hover .img-overlay {
  opacity: 1;
}
.uploaded-img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-overlay {
  opacity: 0;
  display: grid;
  position: absolute;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
}
/*# sourceMappingURL=src/peeks/concierge/file-upload-card.css.map */