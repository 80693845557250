




















































































.denied
  max-width 60vw
  margin auto
.dense
  padding: 8px 16px
  color: #fff
