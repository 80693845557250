.topBar {
  height: 75px;
}
.buttonBar {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonBar .q-btn {
  width: 100px;
  margin: 0 10px;
}
.mobilityNearbyList {
  z-index: 11;
  width: 800px;
  height: 800px;
  position: absolute;
  background-color: #fff;
  bottom: 0;
  right: calc(50% - 400px);
}
.mobilityNearbyList .q-btn {
  font-size: 0.75em;
  padding: 2px 4px 2px 4px;
}
.departure {
  border-top: solid 1px #8c8c8c;
}
.loadingContainer .spinner {
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
}
@media only screen and (max-width: 800px) {
  .mobilityNearbyList {
    width: 100%;
    right: 0;
  }
}
/*# sourceMappingURL=src/components/MMobilityNearbyDetails/index.css.map */