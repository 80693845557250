.popover {
  width: 600px;
}
.time-field-mobile {
  display: flex;
  display: grid;
}
.picker {
  margin: auto;
  width: 95%;
}
/*# sourceMappingURL=src/components/MFields/time/index.css.map */