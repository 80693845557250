












































































.map, .map-container
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background #d1cfcf
  text-overflow: clip;
