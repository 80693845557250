





















































.loading
  display grid
  width 100%
  height 100%
  *
    margin auto
.q-card, .m-group-title
  margin 0 0 16px !important
.q-toolbar
  border-top 1px solid #e0e0e0
.q-item
  padding 16px
