/**
 * Third-party styles
 */
@require './quasar.styl'
@require '~vue-virtual-scroller/dist/vue-virtual-scroller.css'

/**
 * Setup our stylus bits and bobs
 */
@require './variables/*'
@require './helpers/*'
@require './reset.styl'
@require './animate.css'

/**
 * Global Overrides
 */
.mat .q-layout-page *
  user-select text

.bg
  background-image none

.q-field
  &--filled
    .q-field__control
      background white
      &:before
        opacity 0
      &:hover:before
        opacity 0
    &.q-field--focused
      .q-field__control
        &:before
          opacity 0
        &:hover:before
          opacity 0

// Quasar 0.15 upgrade changed h6 styles to pixels and it broke some layout. so I'VE changed it back
h6
  font-size 1rem
  letter-spacing 0.02em
  margin 0.5rem 0 0.4rem
  font-weight 500


h3
  font-size 2.92rem
  margin 1.46rem 0 1.168rem

a
  text-decoration none

// Margin was removed from Quasar in 482e0fe
// https://bit.ly/2kUc7j8
.q-card
  margin 8px
// Font-size was reduced to 14px in v0.16
.q-card-section
  font-size 1rem
// Margins remove in v0.16
.q-field
  margin-bottom 8px

.q-card-section
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
  width 100%

// Override input and select underline hover color
.q-if:hover:before
  color currentColor !important

.q-tabs-scroller .active {
  background: rgba(255,255,255,0.1)
}


//// BAD FIX FOR LOADING BAR
.q-loading-bar.top {
  display: none!important;
}

@media (min-width: 1200px) {
  .layout-padding {
    padding: 3rem 4rem;
    margin: auto;
  }
}

@media (max-width: 599px)
  .q-layout-padding
    padding: 16px

.q-spinner
  display block
  margin initial

.dots
  margin auto

.modal.minimized .modal-content
  max-width 95vw
  max-height 80vh

.modal-scroll
  max-height 500px

.lt-sm
  @media(min-width 768px)
    display none!important

// Fix for quill editor on ios

.ql-editor {
  -webkit-user-select: text;
}

// style fixes from quasar 1.0 upgrade
p
  margin 0

h6
  line-height 1.12

.bg-brand
  color white

// Override Quasar 1.0 captions because they are too small and do not match old
.q-item__label--caption, .text-caption {
  color: #777
  font-size: 14px
  letter-spacing: 0.01786em
}

// Fix for Quasar 1.7.4 / Quasar Extra 1.4.2 upgrade that stopped `avatar` class from working
img
  &.avatar
    width 50px
    height 50px
    border-radius 50%
    box-shadow $shadow-1
    vertical-align middle

// Default rounded is too rounded
.q-field
  &--outlined
    &.q-field--rounded .q-field__control
      border-radius 20px

.is-disabled
  opacity 0.3
  .q-btn:after
    content ""
    position absolute
    height 4px
    background-color $grey-8
    outline 4px white solid
    top 50%
    left -8px
    right -8px
    transform translateY(-50%) rotate(45deg)
