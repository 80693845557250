

















































































































































































































































































































.scroller
  height calc(100vh - 112px)
  width 100%

.titleBar
  font-weight 1200
  display flex
  flex-direction column
  align-items center

.budgetBox
  height 104px
  position relative

.budgetState
  color white
  border-top-left-radius $generic-border-radius
  border-top-right-radius $generic-border-radius
  font-weight 300
  &.statePositive
    background-color #84cb58
  &.stateNegative
    background-color negative

.rounding
  border-radius $generic-border-radius

.q-btn
  font-weight 500
  font-size .75em

.footer
  width 100%
  background-color white
  position absolute
  bottom 0
  left 0
  right 0
  height 56px
  .signout
    position absolute
    right 32px
    width 371px
    bottom 14px

.editIcon
  position absolute
  z-index 10
  background-color white
  border-radius 100%
  font-size .25em
  line-height .25em
  padding 4px
  bottom 0
  right 0

.loadingBudget
  .spinner
    margin 0
    position absolute
    left calc(50% - 22px)
    top calc(50% - 22px)

.grid-title
  display: grid
  grid-template-columns: auto 21px
  justify-items: center

.btn-offset
  padding-left: 20px
