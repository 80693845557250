





































































.colourPicker
  width 350px
  padding 16px
.colourOption
  width 45px
  height 45px
  display inline-block
  margin 4px
  cursor pointer
.moreColours
  max-height 0px
  opacity 0
  transition max-height .3s
  &.show
    max-height 400px
    opacity 1
