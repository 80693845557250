





















































































































































.title
  font-size: 16px;
  color: rgb(66, 66, 66)

.subtitle
  font-size: 14px

.organisation
  font-size: 12.8px

.subtitle, .organisation
  color: rgb(119, 119, 119)
.subtitle
  text-transform: capitalize

span
  display: block
