.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#add-railcard {
  background: none;
  border: none;
  color: #406e8e;
  font-size: 90%;
  padding: 5px;
  text-align: left;
}
.railcard {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
}
.railcard .q-field {
  flex-grow: 1;
}
.remove-railcard {
  background: none;
  border: none;
  color: #c10015;
  font-size: 90%;
}
/*# sourceMappingURL=src/pages/ondemand/train/railcards.css.map */