.btn-switch {
  margin-right: 16px;
}
.q-toolbar {
  min-height: 64px;
  transition: background-color 250ms;
  will-change: background-color;
}
.q-input {
  max-width: 768px;
}
.page-name {
  font-size: 90%;
}
/*# sourceMappingURL=src/components/MMobileAppbar/index.css.map */