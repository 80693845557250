.status-details {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 5px;
  grid-template-columns: 125px auto;
  align-items: center;
}
.status-icon {
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 90%;
  width: 100%;
}
.status-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 125%;
  text-align: center;
}
.q-banner {
  border-width: 1px;
  border-style: solid;
  margin: 15px 0;
}
.status-text {
  margin-top: 0;
}
/*# sourceMappingURL=src/peeks/user-settings/bikehire/index.css.map */