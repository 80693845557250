.top-sheet {
  padding: 0 1rem;
  position: relative;
  z-index: 100;
}
@media (min-width: 768px) {
  .top-sheet {
    padding: 0 2rem;
  }
}
@media (min-width: 768px) {
  .title margin-top 15px {
    margin-bottom: 15px;
  }
}
h4 {
  text-transform: capitalize;
  font-weight: 300;
}
.table-head {
  display: none;
}
@media (min-width: 768px) {
  .table-head {
    display: flex;
  }
  .title {
    margin-top: 2rem;
    margin-bottom: 0;
  }
}
/*# sourceMappingURL=src/components/MResourceTopSheet/index.css.map */