/*
App reset by Ben Frain @benfrain / benfrain.com

Latest: https://github.com/benfrain/app-reset

An opinionated set of resets suitable for building web applications.

## Accessibility Notes
These resets target HTML elements that typically receive styling defaults by User Agents that I always need to 'undo'.

Be aware that some of these resets have a negative impact on the default usability and accessibility of a web page. Therefore, ensure you add an equivalent accessible style back that matches your project aesthetic.

## You'll want to run this through Autoprefixer You'll typically need to run this through (https://github.com/postcss/autoprefixer) for production. Only essential prefixes are added here (e.g. proprietary property value/pairs) and you'll need to set prefixing relative to your desired browser support matrix.

*/

/*Hat tip to @thierrykoblentz for this approach: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

/*Yes, the universal selector. No, it isn't slow: https://benfrain.com/css-performance-revisited-selectors-bloat-expensive-styles/*/
* {
    /*This gets -webkit specific prefix as it is a non W3C property*/
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    /*Older Androids need this instead*/
    -webkit-tap-highlight-color: transparent;
    /* Most devs find border-box easier to reason about. However by inheriting we can mix box-sizing approaches.*/
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: inherit;
}

/* Switching user-select on for inputs and contenteditable specifically for Safari (see bug link above)*/
input[type],
textarea,
[contenteditable] {
    user-select: text;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    /*We will be adding our own margin to these elements as needed.*/
    margin: 0;
    /*No bold for h tags unless you want it*/
    font-weight: 400;
}

a {
    text-decoration: none;
    color: inherit;
}

/*No bold for b tags by default*/
b {
    font-weight: 400;
}

/*Prevent these elements having italics by default*/
em,
i {
    font-style: normal;
}

/*Mozilla adds a dotted outline around a tags when they receive tab focus. This removes it. Be aware this is a backwards accessibilty step!*/
a:focus {
    outline: 0;
}

input,
textarea,
fieldset {
    appearance: none;
    border: 0;
    padding: 0;
    margin: 0;
    /*inputs and fieldset defaults to having a min-width equal to its content in Chrome and Firefox (https://code.google.com/p/chromium/issues/detail?id=560762), we may not want that*/
    min-width: 0;
    /*Reset the font size and family*/
    font-size: 1rem;
    font-family: inherit;
}

/* For IE, we want to remove the default cross ('X') that appears in input fields when a user starts typing - Make sure you add your own! */
input::-ms-clear {
    display: none;
}

/*This switches the default outline off when an input receives focus (really important for users tabbing through with a keyboard) so ensure you put something decent in for your input focus instead!!*/
input:focus,
textarea:focus {
    outline: 0;
}

input[type="number"] {
    /*Mozilla shows the spinner UI on number inputs unless we use this:*/
    -moz-appearance: textfield;
}

/*Removes the little spinner controls for number type inputs (WebKit browsers/forks only)*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
}

/*SVG defaults to inline display which I dislike*/
svg {
    display: inline-flex;
}

/*Removes the default focusring that Mozilla places on select items. From: http://stackoverflow.com/a/18853002/1147859
Ensure you set `#000` to the colour you want your text to appear */
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
