.concierge-peek .q-textarea .q-field__native {
  min-height: 260px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.concierge-peek::-webkit-scrollbar {
  display: none;
}
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
form {
  border: 2px solid #ddd;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.5%;
}
label {
  width: 100%;
  margin: 10px 0;
  font-weight: 500;
}
label#service-select {
  margin: 0;
  width: 95%;
}
select {
  margin: 10px 0 20px;
  background: #fff;
  height: 36px;
}
textarea,
input,
select {
  border: 2px solid #ddd;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  font-weight: 400;
}
textarea {
  min-height: 230px;
}
#image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button {
  background: #406e8e;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 50%;
  padding: 10px;
  margin: 10px 0;
}
.img-card {
  margin-top: 12px;
  width: 100%;
  display: grid;
  grid-template-columns: auto 24px;
  max-height: 128px;
  overflow-y: hidden;
  justify-items: center;
  align-items: center;
}
.img-card:hover .img-overlay {
  opacity: 1;
}
.uploaded-img {
  cursor: pointer;
  max-width: calc(100% - 24px);
  max-height: 128px;
}
/*# sourceMappingURL=src/peeks/contact/index.css.map */