.passenger-form {
  margin-top: 6px;
  grid-column: 1/span 3;
  height: 171px;
  overflow-y: scroll;
  display: grid;
  grid-row-gap: 12px;
}
.passenger-input {
  width: 100%;
  padding-bottom: 6px;
  border-bottom: 1px solid #999;
}
.passenger-input:focus {
  border-bottom: 2px solid #406e8e;
}
.passenger-label {
  font-size: 12px;
}
.passenger-button {
  background: #406e8e;
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-size: 20px;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 24px;
  line-height: 1.715em;
  padding: 4px;
  font-weight: 500;
  height: 50px;
}
/*# sourceMappingURL=src/components/MLeaveNow/passenger-form.css.map */