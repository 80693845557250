.q-card,
.m-group-title {
  margin: 0 0 16px !important;
}
.q-toolbar {
  border-top: 1px solid #e0e0e0;
}
.separator {
  margin: 18px 0 28px 0;
}
/*# sourceMappingURL=src/peeks/mobility-budget/flights/index.css.map */