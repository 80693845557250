.q-card,
.m-group-title {
  margin: 0 0 16px !important;
}
.q-toolbar {
  border-top: 1px solid #e0e0e0;
}
.avatar {
  width: 400px;
  height: 400px;
}
.btn-upload {
  line-height: 2;
}
/*# sourceMappingURL=src/peeks/organisation/branding/index.css.map */