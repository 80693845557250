


































































































































.detail-modal
  .q-toolbar-title
    padding-top: 8px
    padding-bottom: 8px
  .layout-padding
    padding: 24px
  .q-list
    flex-basis: auto
    max-width: none
    .q-item, .q-item-label header
      img.avatar
        width: 38px
        height: 38px
.loader
  width: 100px
  height: 100px
  margin: auto
