






















































.label
  text-transform capitalize
.booking-card
  border-right 5px solid currentColor
  padding-bottom 8px
.booking-card > *
  display: flex;
  align-items: center;
  justify-content: flex-start;
