























































































.top-sheet
  padding 15px 15px 0 15px
  position relative
  background white
  z-index 100

.removeTopPadding
  padding-top 0
