


























































.q-banner
  border-width 1px
  border-style solid
