.input {
  margin-top: 16px;
}
.shotl-form .input,
.search-btn .input,
.q-item {
  color: #616161;
}
.shotl-form .input {
  margin-top: 0;
  padding-bottom: 0;
}
.search-btn .input {
  padding-bottom: 0;
  margin-top: 0;
}
.search-btn .q-field {
  margin-bottom: 0;
}
.location-search {
  position: relative;
}
.list {
  position: absolute;
  background: #fff;
  z-index: 100;
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-width: 420px;
  animation-duration: 0.3s;
  left: 0;
}
.list.shadow {
  box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
}
.list-inner {
  height: 100%;
}
.loading {
  position: absolute;
  display: grid;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255,255,255,0.9);
  z-index: 100;
  animation-duration: 0.3s;
}
.spinner {
  margin: auto;
}
/*# sourceMappingURL=src/components/MLocationSearch/index.css.map */