









































.overview > div, .repeater > div
  padding 6px 0

