







































































.layout-peek
  height 100vh

.scrollarea
  // overflow auto
  // --webkit-overflow-scrolling touch
  width 100%

.toolbar
  min-height 64px

.footer-toolbar
  display flex
  justify-content space-between

.subtitle
  font-size 75%
  white-space normal

