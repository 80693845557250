
































































.salesLine
  background-color #fafafa
  box-shadow 0px 4px 5px rgba(0, 0, 0, 0.125)
  &:hover
    background-color #efefef
