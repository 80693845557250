





































#spinner {
  animation: rotate 3s infinite linear;
  transform-origin: 50px 50px
}
  svg
    margin: initial
