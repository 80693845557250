























































































.header-content-type
  display flex

  p
    line-height 1.2
    margin-left 16px

.header-price
  color #747474
  small
    display block
    text-align right

  .price-amount
    font-weight 700

.time-info
  line-height 1.1

.weather-text
  text-align right
  margin-right 8px

.emissions-text
  line-height 1.1
  margin-top 6px
  margin-right 8px

