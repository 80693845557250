


















































































.q-card-section
  height 120px
.q-card-section
  font-size 16px
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
.q-card-section-overlay
  background-color transparent
  .q-card-subtitle
    color rgba(0,0,0,0.6)
.col.column
  width 8%
.icon-type
  left 8px
  top 80px
