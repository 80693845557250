.type {
  height: 38px !important;
  width: 38px !important;
  border-radius: 30px;
}
@media (min-width: 768px) {
  .grid {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
  }
}
.link:hover {
  text-decoration: underline;
}
.map-status-button {
  position: absolute;
  right: 12px;
  top: 12px;
  background: none;
  border: none;
  color: #1976d2;
  padding: 0;
}
/*# sourceMappingURL=src/peeks/booking/extra-info.css.map */