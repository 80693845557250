






















































































































































































































































































.q-item
  padding 16px
.q-card, .m-group-title
  margin 0 0 16px !important
