


































div
  position relative
h6
  margin 0
  font-size 1rem
.text-uppercase
  text-transform: uppercase
