











































































































































































































































































































































































































.type
  height 38px!important
  width 38px!important
  border-radius 30px
.grid
  @media (min-width 768px)
    display grid
    height 100%
    grid-template-columns 1fr 1fr

.link:hover
  text-decoration underline

.map-status-button
  position absolute
  right 12px
  top 12px
  background none
  border none
  color primary
  padding 0
