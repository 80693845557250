.item {
  cursor: pointer;
}
strong {
  color: #000;
  margin-left: 15px;
}
.address-text {
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.3px;
}
.close {
  position: sticky;
  width: 100;
}
/*# sourceMappingURL=src/components/MLocationSearch/saved-and-recent.css.map */