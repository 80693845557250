.scroller {
  height: 100%;
}
.empty {
  display: grid;
}
.message {
  margin: auto;
  text-align: center;
}
.message p {
  margin-top: 16px;
  line-height: 1.5;
}
.loading {
  display: grid;
  text-align: center;
  height: 128px;
  width: 100%;
}
/*# sourceMappingURL=src/components/MVInfiniteScroll/index.css.map */