

















































.activity
  background-color white
  height 56px
  -webkit-transition unset
  transition unset
.hover .activity
  background-color #e1e1e1
