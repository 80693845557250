









































































.q-icon
  display inline-flex
  width 100%
  height 100%
  font-size 250%
.rounded
  border-radius 100% !important
.q-avatar
  display inline-flex
  margin 0 auto
