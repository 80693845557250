






































































































































































































































































































.item
  height 56px
  border-bottom 1px solid lighten(black, 55)
  .q-btn
    width 96px

.topBar
  height 50px
.mobilityNearbyList
  z-index 10
  width 800px
  height 350px
  position absolute
  background-color white
  bottom 0
  right calc(50% - 400px)
  .q-btn
    font-size .75em
    padding 2px 4px 2px 4px

.loadingContainer
  .spinner
    position absolute
    left calc(50% - 32px)
    top calc(50% - 32px)

@media only screen and (max-width: 800px)
  .mobilityNearbyList
    width 100%
    right 0
