.index-head {
  display: flex;
  margin-top: 10px;
}
.col-head {
  position: relative;
  padding: 10px 12px;
}
.sort-icon {
  position: absolute;
  top: 8px;
  font-size: 25px;
}
.col-head.sortable {
  cursor: pointer;
  transition: background-color 0.3s;
}
.col-head.sortable:hover {
  background: $grey-300;
}
.dates {
  margin-top: -17px;
}
.btn-wrapper {
  margin-top: 12px;
}
.top-sheet {
  padding: 15px;
  position: relative;
  background: #fff;
  height: 125px;
  z-index: 100;
}
.index-head {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: calc(100% - 25px);
  position: absolute;
  bottom: 0;
  left: 0;
}
/*# sourceMappingURL=src/components/MInvoices/index.css.map */