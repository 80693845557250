


































































.item
  cursor pointer
  color #616161
  padding 15px 15px
  display grid
  grid-template-columns 36px auto
  grid-template-rows 1fr auto
  grid-template-areas "icon label" \ "caption caption"
  align-items center
  justify-content flext-start

.icon
  grid-area icon

.label
  grid-area label

.caption
  grid-area caption
  font-size 80%

.flag
  border-radius: 10px

