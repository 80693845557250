

































































































































.item {
  cursor: pointer;
}

strong {
  color: black;
  margin-left: 15px;
}

.address-text {
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.3px;
}
.close
  position: sticky
  width: 100
