.orgDisabled,
.userDisabled.user {
  opacity: 0.3;
}
.orgDisabled .q-avatar:after,
.userDisabled.user .q-avatar:after {
  content: "";
  position: absolute;
  height: 4px;
  background-color: #616161;
  outline: 4px #fff solid;
  top: 50%;
  left: -8px;
  right: -8px;
  transform: translateY(-50%) rotate(45deg);
}
.orgDisabled.user {
  display: none;
}
/*# sourceMappingURL=src/components/MContentTypeEnable/index.css.map */