.right-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.time-text {
  padding-bottom: 5px;
  display: flex;
  justify-content: flex-end;
}
.curve-one {
  animation-name: fade-in;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  opacity: 0;
}
.curve-two {
  animation-name: fade-in;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
  opacity: 0;
}
.curve-three {
  animation-name: fade-in;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  opacity: 0;
}
@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*# sourceMappingURL=src/components/MTimetableCard/index.css.map */