.q-card {
  scroll-snap-align: center;
  background: #fff;
  min-width: 66vw;
  height: 125px;
  padding: 5px 10px;
  border-radius: 15px;
  display: grid;
  grid-template-columns: auto 24px;
  align-items: start;
  grid-template-areas: "card-name type-icon"   "search-type ."   "buttons buttons";
}
@media (min-width: 768px) {
  .q-card {
    min-width: 60%;
  }
}
.card-name {
  color: #406e8e;
  font-weight: 500;
  grid-area: card-name;
  font-size: 95%;
}
.search-type {
  font-size: 85%;
  grid-area: search-type;
}
.button-holder {
  grid-area: buttons;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
/*# sourceMappingURL=src/components/MMobilityNearbyCards/loadingCard.css.map */