















































































[v-cloak]
  display none
body.platform-ios
  &.no-scroll
    position fixed
  @media screen and (orientation:landscape)
    .q-page.layout-padding
      padding-left min(env(safe-area-inset-left), 12px)
      padding-right min(env(safe-area-inset-right), 12px)
// TODO: once defined this should be called via in-component styling referencing partner config
@font-face {
  font-family: "Trueno"
  src: local("Trueno"), url('./fonts/Trueno/TruenoLight-E2pg.otf') format('truetype')
}
