


























.index-head
  padding 15px 0
  width: 100%
  @media (min-width 1024px)
    width 75%!important
.col-head
  display: flex;
  justify-content: space-between;
  padding-right 40px

  span
    line-height 1.5

.full-width.index-head
  @media (min-width 1024px)
    width 100%!important
