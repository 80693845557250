.peek {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50vw;
  max-width: 768px;
  transition: width 0.2s;
}
@media only screen and (max-device-width: 1024px) {
  .peek {
    width: 100vw;
  }
}
.peek .q-list {
  padding: 0;
}
/*# sourceMappingURL=src/peeks/index.css.map */