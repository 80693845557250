.booking-complete {
  text-shadow: none;
  width: 500px;
  padding: 32px;
  min-height: 55vh;
}
@media (max-width: 768px) {
  .booking-complete {
    margin: 0;
    max-width: 100vw;
    min-height: 100vh;
  }
}
h3 {
  font-weight: bold;
}
p {
  padding-top: 1em;
}
.q-btn {
  margin-top: 2em;
  border: 1px solid currentColor;
}
/*# sourceMappingURL=src/components/MBookingComplete/index.css.map */