

































.smartsearch-timeline-wrapper
  padding 12px 36px 0 36px!important
