























































.buttons
  width 100%
  display flex
  justify-content space-between

#add-railcard
  background none
  border none
  color convert(brandColor)
  font-size 90%
  padding 5px
  text-align left

.railcard
  display flex
  align-items center
  justify-content stretch
  width 100%
  .q-field
    flex-grow 1

.remove-railcard
  background none
  border none
  color $negative
  font-size 90%
