






























































































































.q-card-section-overlay
  background-color transparent
  .q-card-subtitle
    color rgba(0,0,0,0.6)
.col.column
  width 8%
.icon-type
  left 8px
  top 80px

.booking
  width 100%
  overflow: hidden

.unknownBackground
  height 120px
  width 100%
  background-color white

.is-cancelled
  opacity 0.75
  &:after
    content ""
    position absolute
    height 4px
    background-color $grey-8
    outline 4px white solid
    top 50%
    left -12px
    right -12px
    transform translateY(-50%) rotate(45deg)
