.display-chip {
  animation-name: alive;
  animation-duration: 0.75s;
}
a {
  cursor: pointer;
}
a:hover {
  color: #1976d2;
  text-decoration: underline;
}
@-moz-keyframes alive {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes alive {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes alive {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes alive {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
/*# sourceMappingURL=src/peeks/organisation/labels/index.css.map */