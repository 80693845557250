[v-cloak] {
  display: none;
}
body.platform-ios.no-scroll {
  position: fixed;
}
@media screen and (orientation: landscape) {
  body.platform-ios .q-page.layout-padding {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@font-face {
  font-family: "Trueno";
  src: local("Trueno"), url("./fonts/Trueno/TruenoLight-E2pg.otf") format('truetype');
}
/*# sourceMappingURL=src/App.css.map */