.results-wrapper {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 10;
}
.sheet-backdrop {
  background: rgba(0,0,0,0.5);
  animation: fade-in-bg 0.3s forwards;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
}
.search-sheet {
  background: #f5f5f5;
  position: absolute;
  top: 74px;
  right: 67px;
  width: calc(100vw - 20px);
  height: calc(100vh - 84px);
  border-radius: 3px;
}
.btn-close {
  color: #000;
  position: absolute;
  right: 76px;
  top: 84px;
  z-index: 30;
}
.scroller {
  height: calc(100vh - 84px);
}
.results-container {
  height: calc(100vh - 64px);
  overflow: auto;
}
.results {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 150px;
}
.group-title {
  padding: 15px 0;
}
@media (min-width: 768px) {
  .search-sheet,
  .search.active {
    width: 70vw;
  }
  .results {
    width: 85%;
  }
}
@media (min-width: 1024px) {
  .search-sheet,
  .search.active {
    width: 60vw;
  }
  .results {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .search-sheet,
  .search.active {
    width: 50vw;
    max-width: 660px;
  }
  .results {
    width: 75%;
  }
}
@-moz-keyframes fade-in-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=src/components/MSearchResultList/index.css.map */